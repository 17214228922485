<template>
  <!-- 我的订单订单详情 -->
  <el-row class="Detailitem">
    <table :class="indexNum===len-1?'main  lastmain':'main'" >
      <!-- <thead>
        <th>订单编号</th>
        <th>订单商品</th>
        <th>状态</th>
        <th>下单时间</th>
        <th>操作</th>
      </thead> -->
      <tbody>
        <template>
          <tr>
            <td>{{ refundList.orderId }}</td>
            <td>
              <div
                class="imgBox"
              >
                <img :src="item.goodsPic.split(',')[0]  | defaultImage" alt=""   v-for="(item, i) in refundList.orderDetailList"
                :key="i"/>
              </div>
              <div class="desBox">
                已退{{refundList.orderDetailList.length}}种商品
              </div>
            </td>
            <td>
              <!-- 1：待审核，2：已通过，3：不通过，4受理中，5已受理，6退货完成，7撤销申请 -->
              {{
                refundList.status === 1
                  ? "待审核"
                  : refundList.status === 2
                  ? "已通过"
                  : refundList.status === 3
                  ? "不通过"
                  : refundList.status === 4
                  ? "受理中"
                  : refundList.status === 5
                   ? "已受理"
                   : refundList.status === 6
                   ? "退货完成":"撤销申请"
              }}
            </td>
            <td>{{ refundList.createTime }}</td>
            <td  class="btnArr"> 
              <span @click="jumpDetail(refundList.orderId)">订单详情</span> <span  @click="jumpAfterRefundDetail(refundList.orderId,refundList.id)">售后详情</span> 
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </el-row>
</template>

<script>
export default {
  name: "BackDetail",
  //   data() {
  //     return {
  //       imgSrc:
  //         "https://3akx.oss-cn-beijing.aliyuncs.com/upload/image/202009/7857272f-247b-4f00-8186-4950fca94c14_thumbnail.jpg",
  //     }
  //   },
  data() {
    return {};
  },
  props: {
    refundList: {
      type: Object,
    },
    indexNum:{
      type:Number
    },
    len:{
       type:Number
    }
  },
  methods: {
    jumpAfterRefundDetail(orderId,backId){
            sessionStorage.setItem("backId",backId)
             sessionStorage.setItem("orderId",orderId)
            this.$router.push({name:"AfterRefundDetail"})
    },
    jumpDetail(orderId) {
      this.$router.push({ path: "/OrderDetail", query: { orderId: orderId } });
    },
    jumpCart() {
      this.$router.push({ name: "Cart" });
    },

    handleClick() {
    },
  },
  created() {
  },
};
</script>

<style lang="less" scoped>
@import "~style/index.less";
.btnArr{
  span{
    cursor: pointer;
    &:hover{
                color: #ff3333;
            }
  }
}
.Detailitem {
  background: #fff;
  font-size: 12px;
  color: #999;
  table.main {
    width: 100%;
    border-collapse: collapse;
    &.lastmain{
       border: 1px solid #e9eaec;
    }
    th,
    td {
      border: 1px solid #e9eaec;
      border-bottom: none;
      width: 150px;
      line-height: 30px;
      text-align: center;
      &:nth-child(2) {
          width: calc(100% - 600px);
          text-align: left;
        }
    }
    .imgBox {
      float: left;
      // width:calc(100% - 130px);
      height:100px;
      padding:0 15px;
      overflow: hidden;
      border: none;
      img {
        display: inline-block;
        border: 1px solid #f6f6f6;
        margin-top:15px;
        width: 70px;
        height: 70px;
        overflow: hidden;
        margin-right:10px;
      }
    }
    .desBox {
      float: right;
      line-height: 100px;
      width:100px;
    }
  }
}
</style>
